//import './shared/utils/YupTranslation'
//import 'react-toastify/dist/ReactToastify.css'
//import { BrowserRouter } from 'react-router-dom'
//import { ToastContainer } from 'react-toastify'
//
//import { ThemeProvider } from '@mui/material'
//
//import { AppRoutes } from './routes'
//import { DrawerProvider } from './shared/contexts'
//import { AuthProvider } from './shared/contexts/AuthContext'
//import { DefaultTheme } from './shared/themes'
//
//export const App = () => {
//  return (
//    <>
//      <ToastContainer
//        position="top-right"
//        autoClose={5000}
//        hideProgressBar={false}
//        newestOnTop={false}
//        closeOnClick
//        rtl={false}
//        pauseOnFocusLoss
//        draggable
//        pauseOnHover
//        theme="light"
//      />
//      <AuthProvider>
//        <ThemeProvider theme={DefaultTheme}>
//          {/* <CssBaseline /> usar isso para correção do css do mui, fazer correções de css antes de mandar para prod */}
//          <DrawerProvider>
//            <BrowserRouter>
//              <AppRoutes />
//            </BrowserRouter>
//          </DrawerProvider>
//        </ThemeProvider>
//      </AuthProvider>
//    </>
//  )
//}

export const App = () => {
  return <h1>Manutenção</h1>
}
